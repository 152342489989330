/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useMemo, useState, ReactNode } from 'react';

interface State {
  internalName: string;
  mediaCentreFilters: any[];
  mediaCentreSelectedFilters: any[];
}

interface ContextType {
  state: State;
  updateInternalName: (internalName: string) => void;
  updateMediaCentreFilters: (mediaCentreFilters: any[]) => void;
  updateMediaCentreSelectedFilters: (mediaCentreSelectedFilters: any[]) => void;
  toggleMediaCentreSelectedFilter: (filter: any) => void;
  resetState: () => void;
}

const defaultState: State = {
  internalName: '',
  mediaCentreFilters: [],
  mediaCentreSelectedFilters: [],
};

// eslint-disable-next-line max-len
const StateContext = createContext<ContextType>({
  state: defaultState,
  updateInternalName: () => {},
  updateMediaCentreFilters: () => {},
  updateMediaCentreSelectedFilters: () => {},
  toggleMediaCentreSelectedFilter: () => {},
  resetState: () => {},
});

const StateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<State>(defaultState);

  const updateInternalName = (internalName: string) => setState(prevState => ({
    ...prevState,
    internalName,
  }));

  const updateMediaCentreFilters = (mediaCentreFilters: any[]) => setState(prevState => ({
    ...prevState,
    mediaCentreFilters,
  }));

  // eslint-disable-next-line max-len
  const updateMediaCentreSelectedFilters = (mediaCentreSelectedFilters: any[]) => setState(prevState => ({
    ...prevState,
    mediaCentreSelectedFilters,
  }));

  const toggleMediaCentreSelectedFilter = (filter: any) => setState(prevState => {
    const { mediaCentreSelectedFilters, ...rest } = prevState;
    const isAlreadySelected = mediaCentreSelectedFilters.includes(filter);
    const newSelectedFilters = isAlreadySelected
      ? mediaCentreSelectedFilters.filter(item => item !== filter)
      : [...mediaCentreSelectedFilters, filter];
    return { ...rest, mediaCentreSelectedFilters: newSelectedFilters };
  });

  const resetState = () => setState(defaultState);

  const value = useMemo(() => ({
    state,
    updateInternalName,
    updateMediaCentreFilters,
    updateMediaCentreSelectedFilters,
    toggleMediaCentreSelectedFilter,
    resetState,
  }), [state]);

  return (
    <StateContext.Provider value={value}>
      {children}
    </StateContext.Provider>
  );
};

export { StateContext, StateProvider };
