import { StateProvider } from './src/components/StateStore';
import { BladeProvider } from './src/state/BladeProvider';
import { ModalProvider } from './src/state/ModalProvider';
import { ProvinceProvider } from './src/state/ProvinceProvider';

import './static/fonts/fonts.css';

export const wrapRootElement = ({ element }) => (
  <ProvinceProvider>
    <ModalProvider>
      <BladeProvider>
        <StateProvider>
          {element}
        </StateProvider>
      </BladeProvider>
    </ModalProvider>
  </ProvinceProvider>
);

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  // dont scroll for faq page
  const disableScroll = location.pathname === '/en/faqs/' || location.pathname === '/fr/faqs/';
  return !disableScroll;
};
