import {
  createContext, useContext, useState, ReactNode, useMemo,
} from 'react';

export enum BladeType{
  MENU,
  QUOTE,
  CLAIM,
}

interface BladeContextType {
  activeBlade: null | BladeType;
  setActiveBlade: (value: null | BladeType) => void
}

const BladeContext = createContext<BladeContextType | undefined>(undefined);

export const useBlade = () => {
  const context = useContext(BladeContext);
  if (!context) {
    throw new Error('useBlade must be used within a BladeProvider');
  }
  return context;
};

export const BladeProvider = ({ children }: { children: ReactNode }) => {
  const [activeBlade, setActiveBlade] = useState(null as null | BladeType);

  const value = useMemo(
    () => ({
      activeBlade,
      setActiveBlade,
    }),
    [activeBlade],
  );

  return (
    <BladeContext.Provider value={value}>
      {children}
    </BladeContext.Provider>
  );
};
