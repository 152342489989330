import {
  createContext, useContext, useState, useMemo,
  useCallback,
  PropsWithChildren,
} from 'react';
  
export enum ModalType {
  SELF_SERVE = "SELF_SERVE"
}

interface ModalContext {
  activeModals: ModalType[];
  openModal: (type: ModalType) => void,
  closeModal: (type: ModalType) => void;
}

const ModalContext = createContext<ModalContext | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [activeModals, setActiveModals] = useState<ModalType[]>([]);

  const removeModal = useCallback((type: ModalType) => activeModals.filter(
    modal => modal !== type,
  ), [activeModals]);
  const openModal = useCallback(
    (type: ModalType) => setActiveModals([type, ...removeModal(type)]),
    [removeModal],
  );
  const closeModal = useCallback((type: ModalType) => setActiveModals(
    removeModal(type),
  ), [removeModal]);

  const value = useMemo(() => ({
    activeModals,
    openModal,
    closeModal,
  }), [activeModals, openModal, closeModal]);

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};
