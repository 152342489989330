import { useGeolocation } from '@pcf-engineering/pcs-ui-kit/hooks';
import {
  createContext, useContext, useState, useMemo,
  useEffect,
  PropsWithChildren,
} from 'react';
  
export enum Provinces {
    AB = "AB", // Alberta
    BC = "BC", // British Columbia
    MB = "MB", // Manitoba
    NB = "NB", // New Brunswick
    NF = "NF", // Newfoundland and Labrador
    NS = "NS", // Nova Scotia
    ON = "ON", // Ontario
    PE = "PE", // Prince Edward Island
    QC = "QC", // Quebec
    SK = "SK" // Saskatchewan
  }
  
  interface ProvinceContextType {
    activeProvince: Provinces | null;
    setActiveProvince: (value: Provinces | null) => void
  }
  
const ProvinceContext = createContext<ProvinceContextType | undefined>(undefined);
  
export const useProvince = () => {
  const context = useContext(ProvinceContext);
  if (!context) {
    throw new Error('useProvince must be used within a ProvinceContext');
  }
  return context;
};
  
const isValidProvince = (province?: string | null) => province
        && Object.values(Provinces).includes(province as Provinces);

export const ProvinceProvider = ({ children }: PropsWithChildren) => {
  const [activeProvince, setActiveProvince] = useState<Provinces | null>(null);
  const { province: provinceFromGeoLocation } = useGeolocation();

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const provinceFromLocalStorage = localStorage.getItem("savedProvince");
    if (isValidProvince(provinceFromLocalStorage)) {
      setActiveProvince(provinceFromLocalStorage as Provinces);
    } else if (isValidProvince(provinceFromGeoLocation)) {
      setActiveProvince(provinceFromGeoLocation as Provinces);
    } else {
      setActiveProvince(Provinces.ON);
    }
  }, [provinceFromGeoLocation]);

  const value = useMemo(() => ({
    activeProvince,
    setActiveProvince: (province?: Provinces | null) => {
      if (typeof window === 'undefined' || !isValidProvince(province)) return;
      setActiveProvince(province as Provinces);
      localStorage.setItem("savedProvince", province as string);
    },
  }), [activeProvince]);

  return (
    <ProvinceContext.Provider value={value}>
      {children}
    </ProvinceContext.Provider>
  );
};
